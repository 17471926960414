export type PaymentReducerState = {
  loading: boolean
  error?: Error
} & Record<string, any>

export const initialProductsState = { loading: false }

const paymentReducer = (state = initialProductsState, action) => {
  switch (action.type) {
    case 'POST_DAD_PAYMENT_REQUEST':
    case 'POST_SUB_PAYMENT_REQUEST':
      return { loading: true }
    case 'POST_DAD_PAYMENT_SUCCESS':
    case 'POST_SUB_PAYMENT_SUCCESS':
      return { loading: false, ...action.paymentResult }
    case 'POST_DAD_PAYMENT_FAILURE':
    case 'POST_SUB_PAYMENT_FAILURE':
      return { loading: false, error: action.error }
    default:
      return state
  }
}

export default paymentReducer
